.left-sidebar {
    @media(min-width: 768px) and (max-width: 991px) {
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
    }
}
.category-filter {
    h4 {
        font-size: 17px;
        border-bottom: 1px solid #DFDFDF;
        padding-bottom: 15px;
        cursor: pointer;
        @media(max-width: 767.98px) {
            border: 1px solid #dfdfdf;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
        }
    }
    ul {
        padding: 0;
        margin: 0;
        @media(max-width: 767.98px) {
            padding-top: 10px;
            width: 185px;
            background-color: rgb(94, 93, 93,.9);
            display: none;
            &.show-filter {
                display: block;
                position: absolute;
                z-index: 1;
                border-radius: 5px;
            }
            &.show-sort {
                display: block;
                position: absolute;
                z-index: 1;
                border-radius: 5px;
            }
        }
        li {
            align-items: center;
            font-weight: 500;
            font-size: 15px;
            @media(max-width: 767.98px) {
                // padding-top: 5px;
                margin-bottom: 10px;
                border-bottom: 1px solid rgb(223, 223, 223,.5);
                color: var(--white);
                cursor: pointer;
                label {
                    padding-top: 1px;
                    padding-bottom: 6px;
                    width: 100%;
                    cursor: pointer;
                }
                &:last-child {
                    border-bottom: 0;
                }
            }

            label {
                cursor: pointer;
                @media(min-width: 576px) {
                    padding-top: 4px;
                }
                span {
                    &:first-of-type {
                        margin-right: 8px;
                        width: 17px;
                        height: 17px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 2.5px;
                        font-size: 12px;
                        font-weight: 700;
                        @media(min-width: 768px) {
                            color: #ada9a9;
                            border: 1px solid #DFDFDF;
                        }
                        @media(max-width: 767.98px) {
                            margin-right: 5px;
                            margin-left: 5px;
                        }
                        i {
                            display: none;
                        }
                    }
                    &:last-child {
                        font-weight: 500;
                        @media(max-width: 767.98px) {
                            text-transform: lowercase;
                            &::first-letter {
                                text-transform: uppercase;
                            }
                        }
                    }
                }
                input:checked  {
                    + span {
                        &:first-of-type {
                            i {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.product-sort {
    h4 {
        font-size: 17px;
        border-bottom: 1px solid #DFDFDF;
        padding-top: 30px;
        padding-bottom: 15px;
        cursor: pointer;
        @media(max-width: 767.98px) {
            border: 1px solid #dfdfdf;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
        }
    }
    ul {
        padding: 0;
        margin: 0;
        @media(max-width: 767.98px) {
            padding-top: 10px;
            width: 185px;
            background-color: rgb(94, 93, 93,.9);
            display: none;
            &.show-sort {
                display: block;
                position: absolute;
                z-index: 1;
                border-radius: 5px;
            }
        }
        li {
            align-items: center;
            font-weight: 500;
            font-size: 15px;
            @media(max-width: 767.98px) {
                // padding-top: 5px;
                margin-bottom: 10px;
                border-bottom: 1px solid rgb(223, 223, 223,.5);
                color: var(--white);
                cursor: pointer;
                label {
                    padding-top: 1px;
                    padding-bottom: 6px;
                    width: 100%;
                    cursor: pointer;
                }
                &:last-child {
                    border-bottom: 0;
                }
            }

            label {
                cursor: pointer;
                @media(min-width: 576px) {
                    padding-top: 4px;
                }
                span {
                    &:first-of-type {
                        margin-right: 8px;
                        width: 17px;
                        height: 17px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 2.5px;
                        font-size: 12px;
                        font-weight: 700;
                        @media(min-width: 768px) {
                            color: #ada9a9;
                            border: 1px solid #DFDFDF;
                        }
                        @media(max-width: 767.98px) {
                            margin-right: 5px;
                            margin-left: 5px;
                        }
                        i {
                            display: none;
                        }
                    }
                    &:last-child {
                        font-weight: 500;
                        @media(max-width: 767.98px) {
                            text-transform: lowercase;
                            &::first-letter {
                                text-transform: uppercase;
                            }
                        }
                    }
                }
                input:checked  {
                    + span {
                        &:first-of-type {
                            i {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.product-change-modal {
    .category-filter {
        ul {
            @media(max-width: 767.98px) {
                background-color: rgb(94, 93, 93,.96);
            }
        }
    }
}
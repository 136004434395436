.imgSizeSlider {
  /* max width of your slider */
  max-width: 300px;
  /* Optional: Only If you want to center your slider in the page */
  margin: auto;
  @media (max-width: 575.98px) {
    display: none;
  }
}

.imgSizeSlider-track {
  /* Top value to align your track to the center of your thumb */
  top: 8px;
  /* thickness of the track */
  height: 4px;
 /* default color of your track */
  background: #DFDFDF;
}

.imgSizeSlider-track.imgSizeSlider-track-0 {
  /* color of the track before the thumb */
  background: var(--green-2);
}

.imgSizeSlider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background: var(--green-2);
  /* shape of the thumb: circle */
  width: 20px;
  height: 20px;
  border-radius: 100%;
  /* remove default outline when selected */
  outline: none;
}

.imgSizeSlider-thumb:hover {
  box-shadow: 0 0 0 8px var(--grey);
}

.imgSizeSlider-mark {
  cursor: pointer;
  top: 6px;
  width: 1.5px;
  height: 8px;
  background-color: var(--green-2);
}
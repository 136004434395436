.page-title-and-start-over {

    .start-over-link {
        color: var(--green);
        margin-top: 20px;
        border:1px solid var(--green);

        &:hover {
	        color: white;
	        background-color: var(--green);
        }
    }


}
.pricing-table {
    .contactUs {
        font-size:16px !important;

        >a {
            font-size:16px !important;
            text-decoration: none !important; 
            &:hover { 
                text-decoration: underline !important; 
            }
        }

    }
}
.start-over-button {

  padding-left:10px;
  padding-right:10px;
  border: none;
  &:hover {
    text-decoration: underline;
  }

}

.build-summary-head {
    font-size: 14px;
    font-weight: 500;

    @media(max-width:479.98px) {
        font-size: 15px;
        line-height: 1.2;
    }

    table {
        width: 100%;

        margin-bottom: 10px;

        tr {
            border-bottom: 1px solid var(--gray);

            &.disabled {
                text-decoration: line-through;
                text-decoration-color: #ddd;

                * {
                    color: #ddd;
                }
                td:last-child {
                    svg {
                        display: inline-block;
                        fill: #888;                            }
                    img {
                        display: none;
                    }
                }

            }

                &.emphasizeLast {
                    border-bottom-color: var(--black) !important;
                }

            &:first-child {
                color: var(--white);
                background-color: var(--black);
                font-weight: 700;
                height: 40px;
            }

            &:last-child {
                border-bottom-color: var(--black);
            }

            th,
            td {
                &:first-child {
                    padding-left: 20px;

                    @media(max-width: 479.98px) {
                        padding-left: 12px;
                    }
                }

                &:last-child {
                    text-align: right;
                    padding-right: 12px;
                    button {
                        svg {
                            display: none;
                        }
                    }
                }

                &.emphasize {
                    font-size: 18px;
                    font-weight: bold;
                    height: 48px;
                }



            }

            td {
                height: 30px;

                &:last-child {
                    button {
                        background-color: #E0E0E0;
                        border: none;
                        width: 100%;
                        height: calc(100% - 6px);

                        &:hover {
                            background-color: #E9ECEF;
                        }
                    }
                }
            }
        }
    }
}
.dashboard {

	.tab-content-container {
		padding-top:16px;
		padding-bottom:10px;
		padding-left:16px;
		padding-right:16px;
		border-left: solid 1px #ddd;
		border-right: solid 1px #ddd;
		border-bottom: solid 1px #ddd;
	}

	table.listing {
	}

	.body {
		margin-left: 10px;
		margin-right:10px;		
	}

	a {
		&:hover {
			color: green !important;
			text-decoration: underline !important;
		}
	}


}

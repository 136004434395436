@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}


html,
body {
  scroll-behavior: smooth;
  height: 100%;
  position: relative;
}

img {
  max-width: 100%;
  height: auto;
}

.ginner-content {
  font-family: "Montserrat", sans-serif;
}
@media only screen and (min-width: 1296px) {
  .ginner-content .container {
    max-width: 1296px;
  }
}

.gprimary-btn {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  background-color: #bf1e24;
  padding: 0.9em 4em;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .gprimary-btn {
    font-size: 16px;
  }
}
.gprimary-btn img {
  padding-left: 5px;
  padding-right: 5px;
  max-width: 65px;
  display: inline-block;
}
.gprimary-btn.white {
  background-color: #ffffff;
  color: #bf1e24;
  border: 2px solid #ffffff;
}
.gprimary-btn:hover {
  text-decoration: none;
  background-color: #bf1e24;
  color: #ffffff;
  border-color: #bf1e24;
}


.ghero-section {

  &.EN {
    background-image: url('/images/launch/GT23_PZ_LP_Header_D_EN.jpg');
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
  }

  &.FR {
    background-image: url('/images/launch/GT23_PZ_LP_Header_D_FR.jpg');
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
  }

  color: #ffffff;
  text-align: center;
  padding: 200px 0px;
  position: relative;
  z-index: -1;
  //margin-top:-10px;
  //padding-top:0px;
}
@media only screen and (max-width: 767px) {
  .ghero-section {
    padding: 80px 0px;
  }
}
.ghero-section::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.4;
  mix-blend-mode: darken;
  z-index: -1;
}
.ghero-section .gtitle {
  color: #ffffff;
  font-weight: 800;
  font-size: 60px;
  line-height: 1;
  margin: 0.7rem auto;
}
@media only screen and (max-width: 767px) {
  .ghero-section .gtitle {
    font-size: 35px;
  }
}
.ghero-section .gsubtitle {
  color: #ffffff;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.3;
  margin-top: 0.25em;
}

.gcustomize-section {
  padding: 80px 0px;
}
@media only screen and (min-width: 1296px) {
  .gcustomize-section .container {
    max-width: 1170px;
  }
}
.gcustomize-section .title {
  color: #34871b;
  font-weight: 800;
  font-size: 40px;
  text-align: center;
  margin-bottom: 0.7em;
  line-height: 120%;
}
@media only screen and (max-width: 767px) {
  .gcustomize-section .title {
    font-size: 27px;
  }
}

@media only screen and (max-width: 767px) {
  .bullet-group {
    padding-left: 0px;
  }
}
.bullet-group .bullet-item {
  -webkit-padding-start: 1ch;
          padding-inline-start: 1ch;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 500;
  font-size: 22px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem;
}
@media only screen and (max-width: 767px) {
  .bullet-group .bullet-item {
    font-size: 16px;
  }
}
.bullet-group .bullet-item:before {
  font-family: "Material Icons";
  content: "task_alt";
  color: #34871b;
  font-size: 26px;
  margin-right: 20px;
}

.text-legal {
  font-weight: 500;
  color: #585858;
}

.custom-options-section {
  background-color: #efefef;
  padding: 60px 0px;
}
.custom-options-section .title {
  color: #000000;
  font-weight: 800;
  font-size: 35px;
  margin-bottom: 0.2em;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .custom-options-section .title {
    font-size: 26px;
  }
}
.custom-options-section .image-content {
  padding-top: 30px;
  padding-bottom: 30px;
}
.custom-options-section .image-content .gimage-wrap {
  -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 5px;
}
.custom-options-section .image-content .gimage-wrap img {
  width: 100%;
  border-radius: 5px;
}
.custom-options-section .image-content .gcontent-text {
  padding: 50px;
  font-weight: 500;
  font-size: 18px;
}
@media only screen and (max-width: 991px) {
  .custom-options-section .image-content .gcontent-text {
    text-align: center;
    padding: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .custom-options-section .image-content .gcontent-text p {
    padding: 25px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .custom-options-section .image-content .gcontent-text p {
    font-size: 16px;
  }
}
.custom-options-section .image-content .gcontent-text h4 {
  text-transform: uppercase;
  color: #000000;
  font-weight: 700;
  font-size: 26px;
}
@media only screen and (max-width: 767px) {
  .custom-options-section .image-content .gcontent-text h4 {
    font-size: 20px;
  }
}

.desc-text {
  color: #363636;
  font-weight: 500;
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  .desc-text {
    font-size: 16px;
  }
}

.help-section {
  padding: 80px 0px 20px;
  font-size: 18px;
  color: #363636;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .help-section {
    font-size: 16px;
  }
}
.help-section .title {
  color: #000000;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 5px;
  text-align: center;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .help-section .title {
    font-size: 22px;
  }
}
.help-section a {
  color: #34871b;
  text-decoration: underline;
}

.promotion-section {
  padding: 80px 0px;
  background-image: url('/images/launch/gt_promo_test_banner_D.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff;
  text-align: center;
  position: relative;
  z-index: 1;
  font-weight: 600;
  font-size: 20px;
  margin-bottom:30px;
}
.promotion-section::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.4;
  mix-blend-mode: darken;
  z-index: -1;
}
@media only screen and (min-width: 1296px) {
  .promotion-section .container {
    max-width: 1000px;
  }
}
.promotion-section img {
  max-width: 500px;
  width: 100%;
}
.promotion-section p {
  margin-top: 20px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .promotion-section p {
    font-size: 16px;
  }
}
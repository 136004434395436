.tabbar {

	font-size: 16px;
	font-weight: 600;

	.nav-link {
		background-color: #eee;
		margin-right:6px;
		color: green;

		&:hover {
			color: #666 !important;
			text-decoration: none !important;
		}

		&.active {
			color: green;
		}
	}



}

.complete-order .front-back-wrapper {
    /*
    .front-back {
        animation-name: rotate360;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        @keyframes rotate360 {
            from {
                transform: rotateZ(0deg);
            }

            to {
                transform: rotateZ(360deg);

            }
        }
    }
    */
}

.front-back-wrapper {
    .front-back {
        position: relative;
        width: 100%;

        @media(max-width: 991.98px) {
            margin-left: auto;
            margin-right: auto;
        }

        @media(max-width:575.98px) {
            width: 100%;
        }

        @media(max-width:350.98px) {
            width: 100%;
        }


        div.logo {
            position: absolute;
            width: 100%;
            height: 100%;
            top: -15px;
            left: 0;

            img {
                width: 234px;
                // margin-top: -10px;

                @media(max-width:575.98px) {
                    width: 185px;
                }

                @media(max-width:350.98px) {
                    width: 150px;
                }
            }
        }

        div.text,
        img.logo {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        div.text {
            font-family: "HelveticaNeue";
            font-size: 26px;
            font-weight: 700;
            line-height: 32px;
            text-transform: uppercase;
            text-align: center;

            @media(max-width:575.98px) {
                font-size: 24px;
                line-height: 28px;
            }

            span {
                display: block;
            }
        }
    }

    .front-back-buttons {
        text-align: center;
        position: relative;
        z-index: 2;
        button {
            min-width: 118px;
        }
    }

    .onstate {
        background-color: #000;
        border-color: transparent;
        color: #fff;
    }
}
.order-summary {
    font-size: 18px;
    @media(max-width: 991.98px) {
        padding-top: 15px;
        border-top: 1px solid #707070;
    }
    @media(max-width:575.98px) {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.4;
    }
    @media(max-width: 383.98px) {
        font-size: 14px;
        letter-spacing: -.5px;
    }
    h3 {
        font-size: 22px;
        font-weight: 700;
    }
    .gray-box {
        background-color: #F4F2F1;
        padding: 15px 10px;
    }



    .total-price-table {
        font-size: 18px;

        @media(max-width:479.98px) {
            font-size: 15px;
            line-height: 1.2;
        }

        width: 100%;

        tr {

            &:last-child {
                border-top: 1px solid var(--gray);
                margin-top: 30px;

            }

            &.disabled {
                text-decoration: line-through;
                text-decoration-color: #ddd;

                * {
                    color: #ddd;
                }
            }

            &.subTotal {
                border-top: 1px solid #ddd;
            }

            &.disclaimer {
                font-size:60%;
                font-style: italic;
            }

            th,
            td {

                &:nth-child(2) {
                    width: 75px;
                    text-align: center;
                }

                &:last-child {
                    width: 40px;
                    text-align: right;
                    padding-right: 12px;
                    button {
                        svg {
                            display: none;
                        }
                    }
                }
            }

        }

        .total-price {
            >div>div>div {
                border-bottom: 1px solid #000;
                position: relative;
                padding-bottom: 5px;
                padding-right: 12px;

                &::after {
                    height: 1px;
                    width: 100%;
                    background: #000;
                    content: '';
                    position: absolute;
                    bottom: -4px;
                }
            }

            >div {
                height: 54px;
            }

            p {
                font-size: 10px;
                font-weight: 500;
                line-height: 16px;
            }

            span {
                font-size: 18px;
                font-weight: 700;
            }
        }

        .buttons {
            button {
                max-width: 100%;

                span {

                    transform: translateY(1px);
                }

                img {
                    width: 13px;
                    transform: scale(1.1) translateY(-1px);
                }

                @media(max-width: 450.98px) {
                    font-size: 12px;
                    min-width: auto;
                }

                @media(max-width: 383.98px) {
                    font-size: 11px;
                }
            }
        }
    }


}
.dashboard-products {

	.boxart {
		min-width:150px;
		padding-bottom: 10px;
	}
	.sideart {
		min-width:200px;
	}
	.topart {
		min-width:100px;
	}
	.descriptionCol {
		min-width:300px;
	}
	.toplogoCol {
		vertical-align: middle;
	}
	.moneyCol {
		vertical-align: middle;
	}
	.notlive {
		background-color: red;
		color:white;
	}
	.live {
		background-color: green;
		color:white;
	}


}

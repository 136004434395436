.customization-boxes {

    .delete {
        margin-top:10px;
    }

    .ball-demo-text {
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 18px !important;
        line-height: 22px;
    }

}

.step-two {
    .no-customizations {
        display: none;
    }
}

	.error {
		color:#FF0000;
		font-size: 80%;
	}

	button.cancel {
		font-size: 80% !important;
		font-weight: bold;
		padding:8px;
		color: white;
		background-color: black;
		border: none;
		border-radius: 8px;
		float:right;
		margin-top:-12px;
		margin-right:-20px;
	}

	.activity {
		font-size:70%;
		font-weight:bold;
	}


	.pause-popover {
		color: green;
	}

	.history-button {
		margin-top:10px;
	}


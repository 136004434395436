.preview {

	@media print { 
	    .container {
	        max-width: none!important;
	        width: 90% !important;
	        padding: 60px 60px 60px 60px !important;
	        display: 'table';
	    }
	    .row {
	    	margin:10px 100px;
	    	display: 'table-row';
	    }
	    .col {
	    	display: 'table-column';
	    	width:50%;
	    }
	    .print-button {
	    	display:none;
	    }
	    p {
	    	font-size: 18px !important;
	    	line-height: 1.6em !important;
	    	margin-bottom:26px;
	    }
	}


	h2 {
		font-size: 16px;
		margin-top:-10px;
		text-align: left;
        @media(max-width:767.98px) {
			margin-top:-10px;
            text-align: center;
        }
	}

	.printButton {
		font-size: 16px;
		margin-top:-10px;
		text-align: right;
        @media(max-width:767.98px) {
			margin-top:-10px;
            text-align: center;
        }

	}

	.icons {
		font-size: 18px;
		margin-top:-10px;
	}

	.col {
		padding: 0px 0px 10px 0px;
		border-bottom: solid 1px #999999;
		margin-bottom: 6px;

		h3 {
			font-size:13px;
			font-weight: bold;
			text-transform: uppercase;
			color:#666666;
			margin-top:4px;
			margin-bottom: 6px;

			&.billing {
				margin-top:16px;
			}
		}

		p {
			line-height: 18px;
			margin-bottom: 8px;
		}

		a {
			color: green;
			&:hover {
				color: black;
			}
		}

	}


		table {
			text-align:left;
			font-family: Hind, sans-serif;
			margin-top:0px;
		}

		td {
			background-color: #ffffff;
			padding:3px 6px 3px 6px;
			min-height: 0px;
			height:0px;
			font-size:14px;
			color:#000000;

			&.money {
				text-align: right;
			}
		}

    tr {
        height: 20px;

        &.lineabove {
        	border-top: 1px solid black;
        }
        &.thicklineabove {
        	border-top: 3px double black;
        }
    }



}

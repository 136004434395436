.step-wrapper {
    min-height: 165px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 20px;

    @media(max-width: 767.98px) {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }

    @media(max-width:575.98px) {
        min-height: 80px;
        padding-left: 12px;
        padding-right: 10px;
    }

    &.fr-bg {
        background: url('/images/header-collage-fr.jpg') no-repeat scroll center center;
    }

    &.en-bg {
        background: url('/images/header-collage-en.jpg') no-repeat scroll center center;
    }

    h2 {
        font-size: 57px;
        margin-bottom: -7px;

        @media(max-width: 1199.98px) {
            font-size: 45px;
            line-height: 1;
        }

        @media(max-width: 991.98px) {
            font-size: 45px;
        }

        @media(max-width: 650.98px) {
            font-size: 40px;
        }

        @media(max-width: 610.98px) {
            font-size: 35px;
        }

        @media(max-width:575.98px) {
            font-size: 26px;
        }

        @media(max-width:460.98px) {
            font-size: 21px;
        }

        &.fr {

            @media(max-width: 767.98px) {
                font-size: 40px;
            }

            @media(max-width: 650.98px) {
                font-size: 35px;
            }

            @media(max-width: 610.98px) {
                font-size: 30px;
            }

        }
    }

    .steps {
        margin-bottom: -2px;
        margin-top: 5px;
        &::after {
            background-color: #fff;
            width: calc(100% - 5px);
            top: calc(50% - 14px);

            @media(max-width:575.98px) {
                width: calc(100% - 20px);
                top: calc(50% - 15px);
            }
        }


        div.no-link {

            display: inline-block !important;

            &:not(:first-child) {
                margin-left: 25px;

                @media(max-width:575.98px) {
                    margin-left: 15px;
                }
            }

            &:nth-child(2) {
                @media(max-width: 420px) {
                    margin-left: 20px;
                    margin-right: -10px;
                }
            }

            span {
                display: block;

                &:first-child {
                    width: 47px;
                    height: 47px;
                    text-align: center;
                    line-height: 46px;
                    font-size: 20px;
                    font-weight: 700;
                    color: #fff;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    background-color: #00A94D;
                    margin-left: auto;
                    margin-right: auto;

                    @media(max-width:575.98px) {
                        width: 35px;
                        height: 35px;
                        font-size: 16px;
                        line-height: 34px;
                    }

                    @media(max-width:460.98px) {
                        width: 30px;
                        height: 30px;
                        font-size: 14px;
                        line-height: 29px;
                    }

                }

                &:nth-child(2) {
                    margin-top: 8px;
                    @media(max-width:575.98px) {
                        margin-top: 5px;
                    }
                }

                &:not(:first-child) {
                    color: var(--white);
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 11px;
                    text-transform: uppercase;
                    @media(max-width:575.98px) {
                        font-size: 8px;
                        line-height: 1.2;
                    }
                }

                &:last-child {}
                &.fr {
                    font-size:7px !important;
                }
            }


            &.active span:first-child {
                background-color: #fff;
                color: #00A94D;
            }

            span {}
        }


    }
}
.dashboard-archived {

	.archived-table {

		thead {
			background-color: green;
			color: white;
		}

		tbody {
			p {
				line-height: 18px;
			}
			a {
				color:green;
			}
			.row-head {
				font-size: 16px;
				line-height: 18px;
			}
		}

	}

	.contact-label {
		font-style: italic;
		font-size: 90%;
		margin-bottom:4px;
		display: inline-block;
	}


}

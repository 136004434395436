.invoice {
    h2 {
        font-size: 26px;
        font-weight: 600;
    }

    .repeat-order-box {
        border: 1px var(--gray) solid;
        border-radius: 8px;
        padding: 16px;
    }

    .invoice-right {

        h4 {
            font-size: 22px;
            font-weight: 700;
        }

        p {
            font-size: 14px;
            line-height: 21px;
        }
        a {
            color: var(--green)
        }
    }

    .order-summary {
        padding-top: 0;
        border-top: 0;
    }

    .print-close-return {

        position: relative;

        margin-top: 30px;

        @media(min-width: 992px) {
            padding-left: 15px;
        }

        @media print {
            display: none !important;
        }

        >div:first-child {
            button {
                &:first-child {
                    background: transparent;
                    border: 0;
                    font-size: 14px;
                    margin-top: -10px;

                    span {
                        padding-top: 3px;
                    }
                }
            }
        }

        >div:last-child {
            margin-top: 20px;
            @media(min-width: 992px) {
                margin-top: 15px;
            }
            @media(max-width: 575.98px) {
                margin-top: 15px;
                width:150px;
            }
            button {
                padding-top: 11px;
            }
        }

    }
}
.top-logo {
    height: 55px;
    @media(min-width: 576px) {
        height: 76px;
    }
    img {
        height: 25px;
        @media(min-width: 576px) {
            height: auto;
        }
    }
}
.change-ball-box {
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    padding-right: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 45px;

    @media(max-width: 1199.98px) {
        padding-left: 20px;
    }

    @media(max-width: 991.98px) {
        padding-right: 15px;
        padding-top: 20px;
        padding-left: 15px;
        max-width: 545px;
        margin-left: auto;
        margin-right: auto;
    }

    h4 {
        font-size: 10px;
        margin-bottom: 5px;
    }

    h3 {
        font-size: 15px;
        font-weight: 400;
    }

    button.price-info {
        font-weight: 600;
        background-color: transparent;
        border: none;

        @media(max-width:575.98px) {
            font-size: 14px;
            line-height: 1.2;
            img {
                width: 25px;
                margin-top: -10px;
            }

        }
    }

    >div {
        >img {
            max-width: 220px;

            // @media(min-width: 768px) and (max-width: 860px) {
            //   max-width: 170px;
            // }

            @media(max-width: 767.98px) {
                max-width: 200px;
            }

            @media(max-width:575.98px) {
                max-width: 200px;
            }

            @media(max-width:410px) {
                max-width: 180px;
            }

            @media(max-width:390px) {
                max-width: 150px;
            }

            @media(max-width:350px) {
                max-width: 120px;
            }
        }
    }
}
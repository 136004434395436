.console-order-summary {

	font-size:14px;
	line-height: 18px;

	.digitization-flag {
		color: red;
		text-transform: uppercase;
		font-weight: bold;
	}

	.riskified_declined {
		display: block;
		margin-top:12px;
		font-size: 14px;
		padding:8px;
		background-color: #ff0000;
		padding-bottom:0px;
		max-width:90%;
	} 

	.riskified_warning {
		display: block;
		margin-top:12px;
		font-size: 14px;
		padding:8px;
		background-color: #FF6347;
		padding-bottom:0px;
		max-width:90%;
	} 

	.riskified_status {
		animation: blinker 1s linear infinite;
		color:#ffffff;
		max-width:80%;
		padding-bottom:4px;
		display:block;
		font-weight: bold;
		font-size:14px;
		text-transform: uppercase;
	} 

	.riskified_description {
		color:#ffffff;
		padding-bottom:4px;
		display:block;
	} 

	@keyframes blinker {
	  90% {
	    opacity: 0.6;
	  }
	}

}

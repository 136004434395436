.view-face {

    .ball-demo-text {
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 26px;
    }

    .customize {

            >div {
                width: 250px;
                height: 250px;
                position: relative;
                margin: auto;

                >div {
                    position: absolute;
                    width: 250px;

                    img {
                        width: 100%;
                    }

                    p {
                        font-size: 16px;
                        //font-weight: 700;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, calc(-50% - 5px));
                        text-align: center;
                        //text-transform: uppercase;
                        line-height:1.2em;

                        span {
                            display: block;
                        }
                    }

                    .logo {
                        width: 130px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, calc(-50% - 7px));

                        img {}

                        &.keyline {
                            border:#00f dotted 1px;
                            border-radius: 50%;
                        }

                    }


                }
            }

    }


}
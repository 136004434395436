.console {

	.btn-outline-danger {
		color: var(--red);
		border-color: var(--red);
		border-radius: 0px;

		&:hover {
			color: #ffffff;
			background-color: var(--red);
		}
	}

	.btn-outline-success {
		border-radius: 0px;
	}

	.btn-connected {
		padding-left:10px;
		padding-right:10px;
		background-color: var(--red);
		color:white;
		border: none;
		font-weight: 500;
        &:hover {
            background-color: #ED001C;
        }
	}

	.badge {
		border-radius: 0px;

		&.bg-danger {
			background-color: var(--red) !important;
		}
	}

	.loginForm {
		margin: auto;
		margin-top:30px;
		width: 300px;
		padding: 20px 20px 24px 20px;

		.error {
			color:#FF0000;
			font-size: 80%;
		}

		.formBody {
			margin-bottom: 22px;
		}
		
	}

	a {
		color:green
	}

	h6 {
		color: var(--red);
	}

	.logo-bar {
		margin-left:12px;
	}

	th {
		&.sortable {
			&:hover {
				color:white;
				text-decoration: underline;
			}
		}
	}

}

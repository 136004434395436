.account-controller {

	text-align: right;
	margin-top:30px;

    @media(max-width:765.98px) {
		text-align: center;
		margin-top:-10px;
    }

    a {
    	color: green !important;
    	&:hover {
    		text-decoration: underline !important;
    	}
    }

}


/*
X-Small            None	<576px
Small              sm	≥576px
Medium             md	≥768px
Large              lg	≥992px
Extra large        xl	≥1200px
Extra extra large  xxl	≥1400px
*/

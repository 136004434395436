.select-vector {

    .vector-explanation {

        background-color:var(--gray);

        h4 {
            margin-top:16px;
        }

        p {
            padding-top: 0px !important;
            margin-top: 0px !important;
        }

        .vector-diagrams {
            display:flex;
            text-align: center;

            .one {
                margin-right:16px;
                justify-content:center;
            }
            .two {
                margin-right:16px;
                justify-content:center;
            }
            p{
                margin-left:-20px;
            }
        }


    }


    @media(max-width:767.98px) {
        .vector-explanation {
            margin-top:30px;
        }
    }


}
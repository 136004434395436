// Mobile Menu

a.red-link {
  color: #ff0000 !important;
}

.d-sm-block {

  .menu-category {
      margin-left:-16px;
      padding-left:16px;
      margin-top:-26px;
      background-color: #ffffff;

            @media(min-width:575.98px) {
                display:none;
            }

    li {
      list-style: none;
      letter-spacing: 1px;
      text-decoration: none;
      text-transform: uppercase;
      padding-top:16px;
      padding-right:16px;
      font-size: 15px;
      font-family: hind, sans-serif;
      font-weight: 500;
      border-bottom: 1px solid rgba(0,0,0,.1);
      width:100%;
      display:block;
      padding-right:16px;
      height:48px;
      a {
        color:#000000;
        &:hover { 
        }
      }
    }

  }

}


// Desktop Menu
.d-md-block {

  .menu-category {
    
    margin-left:-32px;

    li {
      letter-spacing: 1px;
      text-decoration: none;
      text-transform: uppercase;
      display: inline;
      margin-right:32px;
      font-size: 15px;
      font-family: hind, sans-serif;
      font-weight: 500;
      a {
        color:#000000;
        &:hover { 
        }
      }
    }

  }

}
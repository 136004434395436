.gt-footer {

  text-align: center;

  span {
    letter-spacing: 10px;
  }

  padding-bottom:30px;

  a {
    color:#000000;
    font-family: hind,sans-serif;
    font-size: 14px;
    &:hover { 
      text-decoration: underline;
    }
  }

}